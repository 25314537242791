import { useEffect, useMemo } from 'react'

import {
  type GetAdSenseConfigParams,
  getAdSenseConfig,
} from '@/features/advertisement/utils/adsense/getAdsenseConfig'
import { debug } from '@/utils/debug'
import { sendToLogger } from '@/utils/sendToLogger'

declare global {
  interface Window {
    _googCsa: (type: 'ads', options: AdSensePageOptions, ...containers: ContainersProps[]) => void
  }
}

// filtering rules that Google applies to ads matching the search query
export type AdSafe = 'high' | 'medium' | 'low'

// Specifies whether clicking on an ad opens up in the same window or a new window.
export type AdSenseLinkTarget = '_blank' | '_top'

export type AdSensePageOptions = {
  adsafe?: AdSafe
  linkTarget?: AdSenseLinkTarget
  // results page ads are being displayed on, should be 1 for VIP
  adPage?: string
  // AdSense client-ID that comes after 'partner-'.
  pubId?: string
  // ID of the search style, also called templateId (psid)
  styleId: string
  // Key to enable testing mode, should be
  adtest?: string
  // Search query entered by the user in the search bar
  query?: string
  // Used for tracking
  channel?: string
  // Maximum number of characters of a related search term including spaces
  maxTermLength?: number
  // Language for the ads
  hl?: string
}

export type ContainersProps = {
  // ad unit container ID
  container: string
  // should ONLY be used for the top ad unit
  maxTop?: number
  // CSS width of the ad unit container
  width: string | number
  // number of ads to display; Google default is 2
  number?: number
}

export type useAdSenseProps = {
  configParams: GetAdSenseConfigParams
  containers: ContainersProps[]
  active: boolean
  linkTarget?: AdSenseLinkTarget
  adsafe?: AdSafe
}

/**
 * Hook for AdSense (custom search)
 *
 * Docs: https://support.google.com/adsense/answer/9055049?hl=en
 *
 * @param param0
 */
export const useAdSense = ({
  configParams,
  containers,
  active,
  linkTarget = '_blank',
  adsafe = 'high',
}: useAdSenseProps) => {
  /**
   * Workaround for the fact that useEffect does not allow for deep comparison of objects
   * Prevents double AdSense initialization & request due to useEffect firing on every render
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedConfigParams = useMemo(() => configParams, [JSON.stringify(configParams)])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedContainers = useMemo(() => containers, [JSON.stringify(containers)])

  const pageOptions = useMemo(
    () => getAdSenseConfig(memoizedConfigParams, linkTarget, adsafe),
    [memoizedConfigParams, linkTarget, adsafe]
  )

  const generatePageOptionsTable = () => {
    // eslint-disable-next-line no-console
    console.table(pageOptions)
  }

  debug(generatePageOptionsTable)

  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && active) {
        window._googCsa('ads', pageOptions, ...memoizedContainers)
      }
    } catch (e) {
      sendToLogger(`'Could not initialize adsense ad block, _googCsa may be undefined', ${e}`, {
        tags: { component: 'adsense', fn: 'useAdSense' },
        fingerprint: ['useAdSense'],
      })
    }
  }, [active, pageOptions, memoizedContainers])
}
