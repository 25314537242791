import dynamic from 'next/dynamic'
import { FC, useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

import {
  BreadcrumbChevronContainer,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbsNavigation,
  BreadcrumbsOverflowEllipsis,
  BreadcrumbText,
} from './styled'

const ChevronLeftSmallIcon = dynamic(
  () => import('@kijiji/icons/src/icons/ChevronLeftSmall'),
  { ssr: false }
)

type Crumb = {
  label: string
  href?: string
}

export type BreadcrumbsProps = {
  /**
   * A list of objects corresponding to the breadcrumbs to display, in order.
   * The objects include `name`, the name of the breadcrumb; and optionally
   * `url`, the URL the breadcrumb should link to.
   */
  crumbs: Crumb[]

  /**
   * Defines accessibility label for the ellipsis preventing the line from wrapping to the next line
   */
  ellipsisLabel?: string

  /**
   * aria-label of the breadcrumb
   * Accessibility requirement
   */
  label: string

  /**
   * Specifies if the the breadcrumbs should not wrap to a second line on smaller screens
   */
  shouldPreventLineWrap?: boolean
}

const SIDE_PAGE_PADDING = 40
const BREADCRUMBS_GAP = 4

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  crumbs = [],
  ellipsisLabel,
  label,
  shouldPreventLineWrap,
}) => {
  const { colors } = useTheme()

  const breadcrumbsRef = useRef<HTMLOListElement | null>(null)
  const [forceSeeEntireLine, setForceSeeEntireLine] = useState<boolean>(false)
  const [fitsInSingleLine, setFitsInSingleLine] = useState<boolean>(
    !shouldPreventLineWrap
  )

  useEffect(() => {
    if (forceSeeEntireLine || !shouldPreventLineWrap) {
      setFitsInSingleLine(true)
      return
    }

    const checkBreadcrumbsFit = () => {
      if (breadcrumbsRef.current) {
        const containerWidth = document.body.clientWidth

        const childrenWidth = Array.from(
          breadcrumbsRef.current.children
        ).reduce((acc: number, child) => {
          const childWidth = (child as HTMLElement)?.offsetWidth ?? 0

          return acc + childWidth + BREADCRUMBS_GAP
        }, SIDE_PAGE_PADDING)

        setFitsInSingleLine(childrenWidth <= containerWidth)
      }
    }

    /** On page load */
    checkBreadcrumbsFit()

    /** On every other page resizing */
    window.addEventListener('resize', checkBreadcrumbsFit)
    return () => {
      window.removeEventListener('resize', checkBreadcrumbsFit)
    }
  }, [forceSeeEntireLine, breadcrumbsRef])

  const handleEllipsisClick = () => setForceSeeEntireLine(true)

  if (!crumbs.length) return null

  return (
    <BreadcrumbsNavigation
      aria-label={label}
      data-testid="breadcrumbs-nav"
      preventLineWrap={!fitsInSingleLine}
    >
      {!fitsInSingleLine ? (
        <BreadcrumbsOverflowEllipsis
          aria-label={ellipsisLabel}
          onClick={handleEllipsisClick}
        >
          <span />
          <span />
          <span />
        </BreadcrumbsOverflowEllipsis>
      ) : null}

      <BreadcrumbList ref={breadcrumbsRef}>
        {crumbs.map((crumb, index) => {
          const isLastItem = index + 1 === crumbs.length
          const ariaCurrent = isLastItem ? 'location' : undefined

          return (
            <Flex as="li" key={`crumb-${index}`} alignItems="center">
              {!crumb.href ? (
                <BreadcrumbText
                  aria-current={ariaCurrent}
                  color={colors.grey.light1}
                  size="xSmall"
                  weight="medium"
                >
                  {crumb.label}

                  {/* Don't render icon on the last item */}
                  {!isLastItem && (
                    <BreadcrumbChevronContainer>
                      <ChevronLeftSmallIcon aria-hidden />
                    </BreadcrumbChevronContainer>
                  )}
                </BreadcrumbText>
              ) : (
                <BreadcrumbLink
                  aria-current={ariaCurrent}
                  data-testid={`breadcrumb-link-${index}`}
                  href={crumb.href}
                  size="xSmall"
                  variant="secondary"
                  weight="medium"
                >
                  {crumb.label}

                  {/* Don't render icon on the last item */}
                  {!isLastItem && (
                    <BreadcrumbChevronContainer>
                      <ChevronLeftSmallIcon aria-hidden />
                    </BreadcrumbChevronContainer>
                  )}
                </BreadcrumbLink>
              )}
            </Flex>
          )
        })}
      </BreadcrumbList>
    </BreadcrumbsNavigation>
  )
}
