import { type SearchCategoryPath } from '@kijiji/generated/graphql-types'

import { type CategoryPath } from '@/components-page/vip/advertisement/createVipGptConfig'
/**
 * These string methods are ported from BOX and are different to
 * the ones used in extractCategoryNamesFromPath (in @/domain/category).
 *
 * This is because ad targeting terms need to follow
 * a different format, eg:
 * "Toasters & Toaster Ovens" => "toasters__toaster_ovens"
 */

export const deAccent = (accentedString: string) => {
  const nfdNormalizedString = accentedString.normalize('NFD')
  const accentPattern = /[\u0300-\u036f]/g

  return nfdNormalizedString.replace(accentPattern, '').replace('œ', 'oe').replace('Œ', 'OE')
}

export const removeSymbols = (categoryName: string) => {
  const symbolsToRemove = /[&,]/g

  return categoryName.replace(symbolsToRemove, '_')
}

/**
 * Cleans up an array of category path names by:
 * - removing accented characters
 * - replacing ampersands (&) and commas (,) with underscores(_)
 * - removing whitespaces
 * - converting to lowercase
 *
 * @param {SearchCategoryPath[]} [categoryPath] - An array of the category path.
 * @returns {string[]} An array of cleaned category names.
 */
export const cleanCategoryPathForDisplayAds = (
  categoryPath: SearchCategoryPath[] | CategoryPath
): string[] => {
  const cleanedPath = categoryPath.reduce((acc: string[], curr) => {
    // remove "All categories" from the path
    if (curr?.id === 0 || !curr?.localizedName) return acc

    const cleaned = removeSymbols(deAccent(curr.localizedName))
      .replace(/ /g, '_')
      .toLowerCase()
      .replace('–', '-')

    return [...acc, cleaned]
  }, [])

  return cleanedPath
}
