import { FC, InputHTMLAttributes, ReactNode } from 'react'

import { ResponsiveProp } from '@/ui/typings/helpers'

import { ChipCheckbox, ChipCloseIcon, ChipLabel } from './styled'

export type FilterChipProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Unique identifier for the chip.
   * Accessibility requirement to connect the chip to its label
   */
  id: string

  /**
   * The label for the chip
   */
  label: string | ReactNode

  /**
   * Specifies if checkmark should be hidden
   */
  hideCheckmark?: ResponsiveProp<boolean>
}

export const FilterChip: FC<FilterChipProps> = ({
  checked,
  id,
  label,
  hideCheckmark,
  ...rest
}) => {
  return (
    <span>
      <ChipCheckbox type="checkbox" id={id} checked={checked} {...rest} />
      <ChipLabel htmlFor={id} hideCheckmark={hideCheckmark}>
        {label}
        {checked && (
          <ChipCloseIcon aria-hidden data-testid="filter-chip-icon-id" />
        )}
      </ChipLabel>
    </span>
  )
}
