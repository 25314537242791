import { type ListingLocation } from '@kijiji/generated/graphql-types'

import { getLocale } from '@/domain/locale'
import { type LocationPath } from '@/types/location'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'

export const getLocationMarkup = ({
  locationPath,
  coordinates,
  name,
  address,
}: Pick<ListingLocation, 'coordinates' | 'address' | 'name'> & {
  locationPath?: LocationPath[]
}) => {
  if (!locationPath?.length) return {}

  const { apiLocale } = getLocale()

  const country = locationPath[0]?.name?.[apiLocale]
  const region = locationPath[2]?.name?.[apiLocale]

  const latitude = coordinates?.latitude ?? undefined
  const longitude = coordinates?.longitude ?? undefined

  const markup = {
    availableAtOrFrom: {
      '@type': 'Place',
      latitude: latitude,
      longitude: longitude,
      name: name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: address ?? undefined,
        addressLocality: name,
        addressRegion: region,
        addressCountry: country,
      },
    },
  }

  return removeUndefinedFromObject(markup)
}
