import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronCarouselRight: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="chevron-carousel-right_svg__a"
        maskUnits="userSpaceOnUse"
        x={7.5}
        y={4}
        width={9}
        height={16}
        fill="currentColor"
      >
        <path d="M7.5 4h9v16h-9z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.367 5.16a.5.5 0 10-.734.68l5.686 6.159-5.686 6.16a.5.5 0 10.734.678l5.997-6.497a.498.498 0 000-.682L9.367 5.16z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.367 5.16a.5.5 0 10-.734.68l5.686 6.159-5.686 6.16a.5.5 0 10.734.678l5.997-6.497a.498.498 0 000-.682L9.367 5.16z"
      />
      <path
        d="M8.66 5.133L9 5.5l-.34-.367zm.707.028l.368-.34-.368.34zm-.734.678l-.368.34.368-.34zM14.319 12l.367.34a.5.5 0 000-.68l-.367.34zm-5.686 6.16l.367.339-.367-.34zm.028.706L9 18.498l-.34.367zm.706-.028L9 18.497l.367.34zm5.997-6.497L15 12l-.002.002.367.34zM15.5 12h.5-.5zm-.056-.23L15 12l.444-.23zm-.079-.111l-.367.339.002.002.365-.341zM9 5.5l.735-.678a1 1 0 00-1.413-.057L9 5.5zm0 0l-.678-.735a1 1 0 00-.057 1.413L9 5.5zm5.686 6.16L9 5.5l-.735.678 5.686 6.16.735-.678zM9 18.498l5.686-6.16-.735-.678-5.686 6.16.735.678zm0 0l-.735-.678a1 1 0 00.057 1.413L9 18.498zm0 0l-.678.735a1 1 0 001.413-.057L9 18.498zM14.997 12L9 18.498l.735.678 5.997-6.497-.735-.678zm.002-.002l.73.683a.998.998 0 00.27-.683h-1zm0 0h1a.997.997 0 00-.112-.46l-.888.46zm0 0l.888-.46a.998.998 0 00-.158-.223l-.73.683zM9 5.5l5.997 6.497.735-.678-5.997-6.497L9 5.5z"
        mask="url(#chevron-carousel-right_svg__a)"
      />
    </Icon>
  )
}
export default SvgChevronCarouselRight
