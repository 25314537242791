import { AdSenseType } from '@/features/advertisement/constants/adsense'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'

export const getPageTypeForAdsense = (
  keywordsOrTitle: string,
  pageType?: GA_PAGE_TYPE
): AdSenseType => {
  if (pageType === GA_PAGE_TYPE.VIEW_AD) return AdSenseType.VIP

  const isEmptyKeyword = keywordsOrTitle.trim().length === 0
  return isEmptyKeyword ? AdSenseType.BRP : AdSenseType.SRP
}
