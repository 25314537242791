import { type GetListingQuery } from '@kijiji/generated/graphql-types'
import { KIJIJI_LOGO } from '@kijiji/seo/constants'

import { type Listing } from '@/types/search'

export type ImagesStructuredMarkupProps = {
  /**
   * The listing to populate details of the image markup
   * i.e. urls, title, description, etc
   */
  listing: Listing | GetListingQuery['listing']

  /**
   * Specifies if it should include the main image only in the markup
   * i.e. Search pages would only include single images, and view item page would include all of them
   * */
  mainImageOnly?: boolean
}

/**
 * Function to fet the structured markup for listing images
 * up to 4 images
 */
export const getImagesStructuredMarkup = ({
  mainImageOnly,
  listing,
}: ImagesStructuredMarkupProps) => {
  if (!listing) return {}

  const { imageUrls, title, description, url } = listing

  const mainImage = imageUrls[0]

  const mainImageMarkup = { image: mainImage ?? KIJIJI_LOGO }

  /**
   * We will return only the main
   * TODO: SEO Tam to verify the Kijiji_LOGO fallback in the SRP
   */
  if (mainImageOnly) {
    return mainImageMarkup
  }

  if (!listing.imageUrls.length) return {}

  /**
   * List of images to be appended in the markup - up to 4
   *
   */
  const imagesMarkup = imageUrls.slice(0, 4).map((imageUrl, index) => {
    const markup = {
      '@type': 'ImageObject',
      creditText: 'Kijiji',
      name: title,
      description,
      url,
      contentUrl: imageUrl,
    }

    /** We differentiate the main image from the rest */
    if (index === 0) {
      return { ...markup, primaryImageofPage: true }
    }

    return markup
  })

  return {
    ...mainImageMarkup,
    images: imagesMarkup,
  }
}
