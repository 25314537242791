import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgArrow: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 13l3.5 3.5M20 16.5L16.5 20M20 16.5h-9"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
export default SvgArrow
