import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgDimensions: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.533 26.532c0 .515.418.933.933.933H27.531a.933.933 0 00.934-.933v-8a.933.933 0 00-.934-.933H13.4v-12.4a.933.933 0 00-.933-.932h-8a.933.933 0 00-.933.933v21.332zm9.866-7.067v6.133h2.533v-1.066a.933.933 0 011.867 0v1.066h1.466v-3.066a.933.933 0 111.867 0v3.066h1.466v-1.066a.933.933 0 111.867 0v1.066h2.133v-6.133H13.399zm-1.866 6.133H5.4V6.133h6.133v2.133h-1.067a.933.933 0 100 1.867h1.067v1.466H8.466a.933.933 0 100 1.867h3.067v1.466h-1.067a.933.933 0 100 1.867h1.067v1.467H8.466a.933.933 0 100 1.866h3.067v2.133h-1.067a.933.933 0 000 1.867h1.067v1.466z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgDimensions
