import { type GetListingQuery } from '@kijiji/generated/graphql-types'
import { priceFromCents } from '@kijiji/money'
import { JSON_LD_SCHEMA, JSON_LD_SCHEMA_TYPES, JSON_LD_SCHEMA_URLS } from '@kijiji/seo/constants'
import { add, format } from 'date-fns'
import { type TFunction } from 'next-i18next'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { getProductSchemaType } from '@/features/seo/utils/getProductSchemaType'
import { getAutosAttributesMarkup } from '@/features/seo/utils/structured-markups/getAutosAttributesMarkup'
import {
  type ImagesStructuredMarkupProps,
  getImagesStructuredMarkup,
} from '@/features/seo/utils/structured-markups/getImageStructuredMarkup'
import { getLocationMarkup } from '@/features/seo/utils/structured-markups/getLocationMarkup'
import { getRealEstateAttributesMarkup } from '@/features/seo/utils/structured-markups/getRealEstateAttributesMarkup'
import { type Listing } from '@/types/search'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'

type SingleListingStructureMarkupProps = {
  /**
   * Explicitly specifies if certain markups are included for the listing
   */
  includeMarkup?: Pick<ImagesStructuredMarkupProps, 'mainImageOnly'> & {
    location?: boolean
    offerDateDetails?: boolean
  }
  /**
   * Specifies the listing to generate the markup
   * Could be the generic listing or the one returned from search
   */
  listing: Listing | GetListingQuery['listing']
  /**
   * if this listing is part of a list of other listings we can define its position
   */
  position?: number
  /**
   * Specifies the i18n translation function
   */
  t: TFunction
}

/**
 * This function returns structured markup for a single listing
 */
export const getSingleListingStructuredMarkup = ({
  includeMarkup,
  listing,
  position,
  t,
}: SingleListingStructureMarkupProps) => {
  const listingPrice = listing?.price
  /** It should only return the markup if the listing has a price amount */
  if (!listing || !isAmountPrice(listingPrice)) return

  const { title, description, url, categoryId: listingCategoryId, location } = listing

  const imagesMarkup = getImagesStructuredMarkup({
    listing,
    mainImageOnly: includeMarkup?.mainImageOnly,
  })

  const hasVin = listing.attributes?.all?.find((item) => item?.canonicalName === ATTRIBUTES.VIN)
  const productSchemaType = getProductSchemaType(listingCategoryId, !!hasVin)

  const isRealEstate = productSchemaType === JSON_LD_SCHEMA_TYPES.REAL_ESTATE
  const isService = productSchemaType === JSON_LD_SCHEMA_TYPES.SERVICE
  const isVehicle =
    productSchemaType === JSON_LD_SCHEMA_TYPES.CAR ||
    productSchemaType === JSON_LD_SCHEMA_TYPES.MOTORCYCLE ||
    productSchemaType === JSON_LD_SCHEMA_TYPES.VEHICLE

  // TODO: move this into poster info
  // Some listings have a company logo in the attributes
  const companyLogo = listing?.attributes?.all?.find(
    (item) => item?.canonicalName === 'company-logo'
  )?.canonicalValues?.[0]

  const autosMarkup = isVehicle ? getAutosAttributesMarkup(listing, t) : {}
  const realEstateMarkup = isRealEstate ? getRealEstateAttributesMarkup(listing) : {}

  const locationMarkup = includeMarkup?.location
    ? getLocationMarkup({ ...location, locationPath: getLocationPath(listing.location.id) })
    : {}

  const offerDateDetails = includeMarkup?.offerDateDetails
    ? {
        validFrom: listing.activationDate
          ? format(listing.activationDate, 'yyyy-MM-dd')
          : undefined,
        validThrough: listing.activationDate
          ? format(add(listing.activationDate, { months: 1 }), 'yyyy-MM-dd')
          : undefined,
      }
    : {}

  const structuredMarkup = {
    // minimum required properties for product snippets, merchant listings & services
    '@context': JSON_LD_SCHEMA_URLS.BASE,
    '@type': productSchemaType,
    name: title,
    description,
    ...imagesMarkup,
    offers:
      !isService && !isRealEstate
        ? {
            '@type': 'Offer',
            availability: `${JSON_LD_SCHEMA}/InStock`,
            /** Should be the price in dollars without the cents as we can't add a dot or commas */
            price: priceFromCents(listingPrice.amount).dollars,
            priceCurrency: 'CAD',
            ...offerDateDetails,
            ...locationMarkup,
          }
        : null,
    url,
    logo: companyLogo,

    // additional properties applicable for specific categories
    ...autosMarkup,
    ...realEstateMarkup,
  }

  /**
   * If this element is part of a list and has a position, this function will return an list item
   * Or else, it will return the product markup
   */
  if (position) {
    const itemListElement = {
      position,
      item: removeUndefinedFromObject(structuredMarkup),
      '@type': 'ListItem',
    }
    return itemListElement
  }

  return removeUndefinedFromObject(structuredMarkup)
}
