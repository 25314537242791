import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronLeft: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M14.632 6.159a.5.5 0 01.734.678l-5.685 6.16 5.685 6.16a.5.5 0 11-.734.678l-5.997-6.497a.499.499 0 010-.682z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgChevronLeft
