export type AdSenseProps = {
  // Unique ID for the container
  id: 'AFSTop' | 'AFSBottom'
}
/**
 * AdSense component used to display iframes from Google Adsense
 */
export const AdSense = ({ id }: AdSenseProps) => {
  return <ins id={id} aria-hidden="true" data-testid="adsense-ins" />
}
