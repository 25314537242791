import { type SearchCategoryPath } from '@kijiji/generated/graphql-types'

import { type CategoryPath } from '@/components-page/vip/advertisement/createVipGptConfig'
import { AdSenseSettings } from '@/features/advertisement/constants/adsense'
import { type AdSafe, type AdSenseLinkTarget } from '@/features/advertisement/hooks/useAdSense'
import { createAdSenseChannel } from '@/features/advertisement/utils/adsense/createAdsenseChannel'
import { getAdSenseQuery } from '@/features/advertisement/utils/adsense/getAdsenseQuery'
import { getPageTypeForAdsense } from '@/features/advertisement/utils/adsense/getPageTypeForAdSense'
import { type GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { getUserDevice } from '@/utils/getUserDevice'

export type GetAdSenseConfigParams = {
  allCategoryIds: string[]
  locationId?: number
  englishLocationName?: string
  currentPage: number
  keywordsOrTitle: string
  routeLocale: string
  categoryPath: SearchCategoryPath[] | CategoryPath
  experimentName?: string | null
  experimentGroup?: string
  pageType?: GA_PAGE_TYPE
}

export const getAdSenseConfig = (
  {
    allCategoryIds,
    locationId,
    englishLocationName,
    currentPage,
    keywordsOrTitle,
    routeLocale,
    categoryPath,
    experimentName,
    experimentGroup,
    pageType,
  }: GetAdSenseConfigParams,
  linkTarget: AdSenseLinkTarget,
  adsafe: AdSafe
) => {
  // We concatenate two channels, with the second one having `kcan19539_a` passed as the "test experiment group"
  // Ex: (e.g. 0_w_brl_10_o_x_1_o_x+1_w_brl_10_o_x_1_kcan999_a)
  // See google's documentation: https://developers.google.com/custom-search-ads/web/reference

  const adSenseChannels = [
    createAdSenseChannel({
      expInd: '0',
      os: 'w', // hardcoded to match legacy which is always 'w'
      pageType: pageType || getSearchPageType(keywordsOrTitle),
      l1Catid: allCategoryIds[0],
      l2Catid: allCategoryIds[1],
      page: currentPage,
      locationId: locationId || -1,
    }),
    experimentName && experimentGroup
      ? createAdSenseChannel({
          expInd: '1',
          os: 'w', // hardcoded to match legacy which is always 'w'
          pageType: pageType || getSearchPageType(keywordsOrTitle),
          l1Catid: allCategoryIds[0],
          l2Catid: allCategoryIds[1],

          page: currentPage,
          locationId: locationId || -1,
          expName: experimentName,
          expGroup: experimentGroup,
        })
      : undefined,
  ]
    .filter(Boolean)
    .join('+')

  const isMobileDevice = getUserDevice().isPhone
  const adtest = process.env.NEXT_PUBLIC_ADSENSE_TEST_MODE || 'on'

  const currentTagType = getPageTypeForAdsense(keywordsOrTitle, pageType)

  const currentSettings = AdSenseSettings[currentTagType]

  const adSenseConfig = {
    adPage: currentPage?.toString(),
    hl: routeLocale,
    pubId: isMobileDevice ? currentSettings.pubId.mobile : currentSettings.pubId.desktop,
    query: getAdSenseQuery({
      keywordsOrTitle,
      locationName: englishLocationName,
      categories: categoryPath,
    }),
    styleId: isMobileDevice ? currentSettings.styleId.mobile : currentSettings.styleId.desktop,
    channel: adSenseChannels,
    linkTarget,
    adsafe,
    ...(adtest === 'on' && { adtest }),
  }

  return adSenseConfig
}
