import { ThemeProps } from '@kijiji/theme/src'
import styled from 'styled-components'

const interactionStyles = (theme: ThemeProps) => `
  &:hover, &:focus-within {
    background-color: ${theme.colors.purple.light5};
  }

   &:focus-within {
    border-color: ${theme.colors.blue.primary};
    box-shadow: ${theme.boxShadow['shadow-1']};
   
    & > a {
    outline: none;
    }
  }
`

export const RoundedChip = styled.div<{ isNonInteractable?: boolean }>(
  ({ theme, isNonInteractable }) => `
  display: inline-flex;
  border-radius: ${theme.borderRadius.xLarge}; 
  align-items: center;
  padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
  border: .1rem solid ${theme.colors.grey.light3};
  background-color: ${theme.colors.white};
  color: ${theme.colors.grey.primary};

  ${isNonInteractable ? '' : interactionStyles(theme)}
    
`
)
