/**
 * Converts a price in cents to dollars.
 *
 * @param price The price in cents as a number, e.g. 2600033
 * @returns The price in dollars, e.g. 26000.33
 */
export const centsToDollars = (cents: number) => Math.round(cents) / 100

/**
 * Converts a price in dollars to cents.
 *
 * @param price The price in dollars as a number, e.g. 26000.33
 * @returns The price in cents, e.g. 2600033
 */
export const dollarsToCents = (dollars: number) => parseFloat(dollars.toFixed(2)) * 100
