import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING } from '@/components/shared/page-container/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export type SearchListContainerProps = {
  gap?: Record<
    Extract<keyof ThemeProps['breakpoints'], 'small' | 'xLarge'>,
    keyof ThemeProps['spacing']
  >
  isSrpControlsMwebToggleEnabled?: boolean
}

export const SearchListContainer = styled.ul<SearchListContainerProps>(
  ({ theme, gap, isSrpControlsMwebToggleEnabled }) => {
    const containerGap = gap ? theme.spacing[gap.small] : theme.spacing.mini
    return `
      display: flex;
      flex-direction: column;
      gap: ${isSrpControlsMwebToggleEnabled ? theme.spacing.default : containerGap};

      ${
        gap?.xLarge
          ? `${MEDIA_QUERIES(theme).xLarge} {
        gap: ${theme.spacing[gap.xLarge]}
      }`
          : ''
      };

      & a, & span {
        text-decoration: none;
        cursor: pointer;
      }
      & a:hover h3, 
      & a:focus h3,
      & span:hover h3, 
      & span:focus h3 {
        text-decoration: underline;
      }

    `
  }
)

export const TopAdsContainer = styled.div<{
  isSrpControlsMwebToggleEnabled: boolean
}>(
  ({ theme, isSrpControlsMwebToggleEnabled }) => `
  background: ${isSrpControlsMwebToggleEnabled ? `${theme.colors.white}` : `${theme.colors.purple.light5}`};
  padding: ${theme.spacing.default};
  margin: 0 -${PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING}; 

  ${MEDIA_QUERIES(theme).medium}{
    margin: 0;
  }
`
)

export const PaginationLinkContainer = styled(Flex)(
  ({ theme }) => `
  flex-direction: column;

  ${MEDIA_QUERIES(theme).medium}{
    flex-direction: row;
    justifyContent: space-between;
  }
`
)

export const HighlightedKeyword = styled.strong`
  font-weight: bold;
`
