import { type AppliedFilter, type AppliedRangeFilter } from '@kijiji/generated/graphql-types'
import { isPositiveInteger } from '@kijiji/number/isPositiveInteger'

import { isAppliedAttributeFilter, isAppliedRangeFilter } from '@/types/search'
import { centsToDollars } from '@/utils/price'

/**
 * Format applied attribute filters and price to append to the datalayer
 * (Attribute Filters) i.e. <filter_name>:[<value1>, <value2>];
 * (Price) i.e. prcMn:['<min_value>'];prcMx:['<max_value>'];
 *
 * Combine both to be used in the datalayer
 */
const formatPriceFilter = (filter: AppliedRangeFilter): string => {
  let filterString = ''
  if (isPositiveInteger(filter.minValue)) {
    const minValue = centsToDollars(filter.minValue)
    filterString += `prcMn:['${minValue}']`
  }

  if (isPositiveInteger(filter.maxValue)) {
    const maxValue = centsToDollars(filter.maxValue)
    if (filterString) filterString += '; '
    filterString += `prcMx:['${maxValue}']`
  }
  return filterString
}

export const getDatalayerAppliedFilters = (filters?: AppliedFilter[]): string => {
  if (!filters) return ''

  const { attributes, price } = filters.reduce(
    (acc: { attributes?: string; price?: string }, curr: AppliedFilter) => {
      const isPriceFilter = !acc.price && isAppliedRangeFilter(curr) && curr.filterName === 'price'

      if (isPriceFilter) {
        return { ...acc, price: formatPriceFilter(curr) }
      }

      /** Is attribute filter */
      if (isAppliedAttributeFilter(curr)) {
        const filterString = `${curr.filterName}:[${curr.values}]`
        return {
          ...acc,
          attributes: acc.attributes ? `${acc.attributes}; ${filterString}` : filterString,
        }
      }

      return acc
    },
    {}
  )

  if (attributes && price) {
    return `${attributes}; ${price}`
  }

  return attributes ?? price ?? ''
}
