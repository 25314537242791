import { CATEGORIES } from '@kijiji/category'
import { type SearchCategoryPath } from '@kijiji/generated/graphql-types'

import { type CategoryPath } from '@/components-page/vip/advertisement/createVipGptConfig'
import { replaceAmpersands } from '@/utils/string'

export type GetAdSenseQueryProps = {
  keywordsOrTitle: string
  locationName?: string
  categories?: SearchCategoryPath[] | CategoryPath
}

/**
 * Process inputs and return a formatted query for AdSense
 *
 * @param param0 - AdSenseQueryProps
 * @returns <keywordsOrTitle> <search location name> <L2CategoryName or L3CategoryName> or <keywordsOrTitle> <search location name> <L1CategoryName or L2CategoryName or L3CategoryName>
 */
export const getAdSenseQuery = ({
  keywordsOrTitle,
  locationName,
  categories,
}: GetAdSenseQueryProps) => {
  const searchLocationName = locationName ?? 'Canada'

  const listCategories = categories?.reduce<string[]>((acc, category) => {
    if (category?.id === CATEGORIES.BUY_AND_SELL_CATEGORY_ID) {
      return acc
    }
    return [...acc, category?.localizedName ?? '']
  }, [])

  const convertedCategories = listCategories ? replaceAmpersands(listCategories).join(' ') : ''

  return `${keywordsOrTitle} ${searchLocationName} ${convertedCategories}`.trim()
}
