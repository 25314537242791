import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgLength: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4 11.999c0-.516.418-.934.934-.934h21.331c.516 0 .934.418.934.934v8a.933.933 0 01-.934.932H5.334a.933.933 0 01-.934-.933v-8zm1.867.933v6.133h1.466v-1.067a.933.933 0 011.867 0v1.067h2.133v-3.067a.933.933 0 111.867 0v3.067h1.466v-1.067a.933.933 0 011.867 0v1.067h1.466v-3.067a.933.933 0 111.867 0v3.067h1.466v-1.067a.933.933 0 111.867 0v1.067h2.133v-6.133H6.267z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgLength
