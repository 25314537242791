import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRvs: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.377 15.812H3.5a.5.5 0 01-.5-.5V9a1 1 0 011-1h15a1 1 0 011 1v.5a1 1 0 01-1 1h-1.5m-4 5.5v-5.5h4m-4 5.5h-6m6 0h2m-2-3h5m0 3H20a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-1.5M9 16v-5.5h2.5V16m6-5.5l1 2.5"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <circle cx={6} cy={16} r={1.5} stroke="currentColor" />
      <circle cx={17} cy={16} r={1.5} stroke="currentColor" />
      <path d="M5 10.5h2v2H5v-2z" stroke="currentColor" strokeLinejoin="round" />
    </Icon>
  )
}
export default SvgRvs
