import { type GetListingQuery } from '@kijiji/generated/graphql-types'
import { JSON_LD_SCHEMA_URLS } from '@kijiji/seo/constants'
import { getAutosWikipediaLink } from '@kijiji/seo/constants/autosWikis'
import { type TFunction } from 'next-i18next'

import {
  ATTRIBUTES,
  CAR_TRANSMISSION_LABELS,
  VEHICLE_TYPE_VALUES,
} from '@/features/attributes/constants/attributes'
import { getSeoAttributesDictionary } from '@/features/seo/utils/getSeoAttributesDictionary'
import { type Listing } from '@/types/search'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'

const getDriveWheelConfiguration = (configuration: string) => {
  switch (configuration) {
    case 'awd':
      return JSON_LD_SCHEMA_URLS.AUTOS.ALL_WHEEL
    case '4x4':
      return JSON_LD_SCHEMA_URLS.AUTOS.FOUR_WHEEL
    case 'fwd':
      return JSON_LD_SCHEMA_URLS.AUTOS.FRONT_WHEEL
    case 'rwd':
      return JSON_LD_SCHEMA_URLS.AUTOS.REAR_WHEEL
    default:
      return undefined
  }
}

const {
  CAR_MAKE,
  MOTORCYCLE_MAKE,
  CAR_BODY_TYPE,
  CAR_COLOR,
  DRIVE_TRAIN,
  MOTORCYCLE_DRIVE_TRAIN,
  CAR_FUEL_TYPE,
  MOTORCYCLE_FUEL_TYPE,
  VEHICLE_TYPE,
  CAR_MILEAGE,
  CAR_MODEL,
  MOTORCYCLE_MODEL,
  NUM_DOORS,
  CAR_TRANSMISSION,
  MOTORCYCLE_TRANSMISSION,
  CAR_TRIM,
  MOTORCYCLE_TRIM,
  CAR_YEAR,
  NUM_SEATS,
  VIN,
} = ATTRIBUTES

/**
 * A map of autos attributes needed to populate the JSON-LD autos markup
 */
const AUTOS_MARKUP_ATTRIBUTES: string[] = [
  CAR_MAKE,
  MOTORCYCLE_MAKE,
  CAR_BODY_TYPE,
  CAR_COLOR,
  DRIVE_TRAIN,
  MOTORCYCLE_DRIVE_TRAIN,
  CAR_FUEL_TYPE,
  MOTORCYCLE_FUEL_TYPE,
  VEHICLE_TYPE,
  CAR_MILEAGE,
  CAR_MODEL,
  MOTORCYCLE_MODEL,
  NUM_DOORS,
  CAR_TRANSMISSION,
  MOTORCYCLE_TRANSMISSION,
  CAR_TRIM,
  MOTORCYCLE_TRIM,
  CAR_YEAR,
  NUM_SEATS,
  VIN,
]

/**
 * Returns a mounted markup for the autos JSON-LD
 */
export const getAutosAttributesMarkup = (
  listing: Listing | GetListingQuery['listing'],
  t: TFunction
) => {
  const attributes = getSeoAttributesDictionary(listing, AUTOS_MARKUP_ATTRIBUTES)

  /**
   * properties for Cars & Trucks are almost identical to Motorcycles
   * https://developers.google.com/search/docs/appearance/structured-data/vehicle-listing
   * https://schema.org/Vehicle
   * https://schema.org/Motorcycle
   * https://schema.org/Car
   */

  const brandName = attributes[CAR_MAKE] ?? attributes[MOTORCYCLE_MAKE] ?? ''

  const itemCondition =
    attributes[VEHICLE_TYPE] === VEHICLE_TYPE_VALUES.NEW
      ? JSON_LD_SCHEMA_URLS.NEW_CONDITION
      : JSON_LD_SCHEMA_URLS.USED_CONDITION

  const model = attributes[CAR_MODEL] ?? attributes[MOTORCYCLE_MODEL] ?? ''
  const driveTrain = attributes[DRIVE_TRAIN] ?? attributes[MOTORCYCLE_DRIVE_TRAIN] ?? ''
  const vehicleConfiguration = attributes[CAR_TRIM] ?? attributes[MOTORCYCLE_TRIM]
  const fuelType = attributes[CAR_FUEL_TYPE] ?? attributes[MOTORCYCLE_FUEL_TYPE]
  const transmission = attributes[CAR_TRANSMISSION] ?? attributes[MOTORCYCLE_TRANSMISSION]

  const markup = {
    /* CAR REQUIRED PROPERTIES */
    brand: { '@type': 'Brand', name: brandName },
    itemCondition,
    mileageFromOdometer: {
      '@type': 'QuantitativeValue',
      value: attributes[CAR_MILEAGE],
      unitCode: 'KMT',
    },
    model,
    /**
     * Not all vehicle listings have a VIN
     */
    vehicleIdentificationNumber: attributes[VIN],
    vehicleModelDate: attributes[CAR_YEAR],

    /* CAR RECOMMENDED PROPERTIES */
    bodyType: attributes[CAR_BODY_TYPE],
    color: attributes[CAR_COLOR],
    driveWheelConfiguration: getDriveWheelConfiguration(driveTrain),
    numberOfDoors: attributes[NUM_DOORS],
    vehicleConfiguration,
    vehicleEngine: { '@type': 'EngineSpecification', fuelType },
    vehicleSeatingCapacity: attributes[NUM_SEATS],
    vehicleTransmission: transmission
      ? t(CAR_TRANSMISSION_LABELS[parseInt(transmission)])
      : undefined,
    sameAs: getAutosWikipediaLink(brandName, model),
  }

  return removeUndefinedFromObject(markup)
}
