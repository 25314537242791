import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronCarouselLeft: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="chevron-carousel-left_svg__a"
        maskUnits="userSpaceOnUse"
        x={7.499}
        y={3.998}
        width={9}
        height={16}
        fill="currentColor"
      >
        <path d="M7.499 3.998h9v16h-9z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.632 5.159a.5.5 0 01.734.678l-5.685 6.16 5.685 6.16a.5.5 0 11-.734.678l-5.997-6.497a.499.499 0 010-.682l5.997-6.497z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.632 5.159a.5.5 0 01.734.678l-5.685 6.16 5.685 6.16a.5.5 0 11-.734.678l-5.997-6.497a.499.499 0 010-.682l5.997-6.497z"
      />
      <path
        d="M15.338 5.13L15 5.499l.34-.367zm-.706.029l-.368-.34.368.34zm.734.678l.368.34-.368-.34zm-5.685 6.16l-.368.339a.5.5 0 010-.678l.368.339zm5.685 6.16l-.367.339.367-.34zm-.028.706L15 18.496l.34.367zm-.706-.028l.367-.34-.367.34zm-5.997-6.497L9 11.997l.002.002-.367.34zm-.135-.341H8h.5zm.056-.23l.444.23-.444-.23zm.079-.111l.367.339-.002.002-.365-.341zm6.364-6.158l-.735-.678a1 1 0 011.413-.057L15 5.498zm0 0l.678-.735a1 1 0 01.057 1.413l-.735-.678zm-5.686 6.16L15 5.498l.735.678-5.686 6.16-.735-.678zM15 18.496l-5.686-6.16.735-.678 5.686 6.16-.735.678zm0 0l.735-.678a1 1 0 01-.057 1.413L15 18.496zm0 0l.678.735a1 1 0 01-1.413-.057l.735-.678zm-5.997-6.497L15 18.496l-.735.678-5.997-6.497.735-.678zM9 11.997l-.73.683a.999.999 0 01-.27-.683h1zm0 0H8a1 1 0 01.112-.46l.888.46zm0 0l-.888-.46a.996.996 0 01.158-.223l.73.683zm5.999-6.5l-5.997 6.498-.735-.679 5.997-6.496.735.678z"
        mask="url(#chevron-carousel-left_svg__a)"
      />
    </Icon>
  )
}
export default SvgChevronCarouselLeft
