import { type GetListingQuery } from '@kijiji/generated/graphql-types'

import { ATTRIBUTES, PET_FRIENDLY_VALUES } from '@/features/attributes/constants/attributes'
import { type Listing } from '@/types/search'

/**
 * This dictionary function is formatting the label based on SEO requirements
 * And listings coming from different sources
 */
export const getSeoAttributesDictionary = (
  listing: Listing | GetListingQuery['listing'],
  includedAttributes: string[]
) => {
  if (!listing?.attributes?.all?.length) return {}

  const attributes = listing.attributes.all.reduce((acc: Record<string, string>, curr) => {
    if (!curr || !includedAttributes.includes(curr.canonicalName)) {
      return acc
    }

    const canonicalValue = curr.canonicalValues?.[0]
    const value = 'values' in curr ? curr.values[0] : undefined

    /**
     * Some attributes need special treatments as SRP only returns the "canonicalValues" fields,
     * the VIP receives "canonicalValues" and "values", but the "canonicalValues" from SRP and VIP don't always match
     *
     * When there is no "value" field, but there is a "canonicalValue", the data is coming from search
     */
    const isSearchData = !value && canonicalValue

    if (curr.canonicalName === ATTRIBUTES.DRIVE_TRAIN) {
      return canonicalValue ? { ...acc, [curr.canonicalName]: canonicalValue } : acc
    }

    if (curr.canonicalName === ATTRIBUTES.BATHROOMS && isSearchData) {
      const numBathrooms = parseInt(canonicalValue) / 10
      return { ...acc, [curr.canonicalName]: numBathrooms.toString() }
    }

    if (curr.canonicalName === ATTRIBUTES.PET_FRIENDLY && isSearchData) {
      return {
        ...acc,
        [curr.canonicalName]: (canonicalValue === PET_FRIENDLY_VALUES.TRUE).toString(),
      }
    }

    if (curr.canonicalName === ATTRIBUTES.CAR_BODY_TYPE) {
      const value = 'values' in curr ? curr.values : curr.canonicalValues
      if (!value?.length) return acc

      // i.e. "SUV, Crossover"
      return { ...acc, [curr.canonicalName]: value.join(', ') }
    }

    if (curr.canonicalName === ATTRIBUTES.CAR_TRANSMISSION) {
      if (!canonicalValue) return acc
      return { ...acc, [curr.canonicalName]: canonicalValue }
    }

    const attrValue = value ?? canonicalValue ?? undefined
    if (!attrValue) return acc

    return { ...acc, [curr.canonicalName]: attrValue }
  }, {})

  return attributes
}
